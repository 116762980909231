import './landing4.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';


import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { ImageLoader } from 'react-loading-image'
import { ImageLoader } from "react-image-and-background-image-fade";
import Daypass from '../component/Homepage/Daypass';
import { PackageType4 } from './PackageType4';
import { apiLink, baseLink, packageTypeOther } from '../constants/DataPublic';
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
// import ReactGA from 'react-ga4';
import { Modal } from 'react-bootstrap';
import logo from "../component/img/logo-xl.png";
import { ReactComponent as Close } from "../component/img/close.svg";

import CircularProgress from '@mui/material/CircularProgress';
import bgSongkran from '../component/img/BG-songkran.jpg';
import bannerBG from '../component/img/banner-bg.jpg';
import { useGlobalState } from '../global'


//Component
const LandingPage4 = (props) => {
  const { language } = props;
  const [routePath, setRoutePath] = useGlobalState('route');
  const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
  const [packageType, setPackageType] = useState([]);
  const [allPackage, setAllPackage] = useState([]);
  const [banner, setBanner] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hardLink1, setHardlink1] = useState({});
  const [hardLink2, setHardlink2] = useState({});
  const [bannerUrl, setBannerUrl] = useState({});
  const [packageTicket, setPackageTicket] = useState([]);
  const [pacBanner, setPacฺBanner] = useState({});
  const [cart, setCart] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [alertText, setAlertText] = useState("Erorrrr");
  const [equalArray, setEqualArray] = useState([]);
  const [notEqualArray, setNotEqualArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
  const [moreTrillItem, setMoreTrillItem] = useState(null)
  const [fbItem, setFbItem] = useState(null)
  const [extra, setExtra] = useState(null)

  //for add to cart cross campaign
  const [itCrossCampaign, setItCrossCampaign] = useState(false);
  const [crossCampaignName, setCrossCampaignName] = useState("B2SCHOOL");
  const [crossPackageTicket, setCrossPackageTicket] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

  let myCart = sessionStorage.getItem('MyCart');
  myCart = JSON.parse(myCart);

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          // dots: false
        },
      },
    ],
  };

  useEffect(() => {
    if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
  }, [cart, myCart]);

  useEffect(async () => {
    sessionStorage.removeItem('itCrossCampaign');
    if (language) {
      if (itCrossCampaign) {
        getPackageTicketCrossCampaign()
      }
      await getBanner();
      await getPackageTicket();
      await getMoreTrill();
      await getFB();
      await getExtra();
      await getAllPackage();
    }
  }, [language]);

  useEffect(async () => {
    let pk = packageTicket.find((x) => x.PackageCode == hardLink1.ButtonLinkUrl);
    setPacฺBanner(pk)
  }, [packageTicket, hardLink1]);

  async function getBanner() {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Banner' + path, requestOptions);
    res
      .json()
      .then((res) => {
        // let sortBanner = res['Result']['PackageBanner'].sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
        setBannerUrl(res['Result']['PackageBanner'][0]);
        if (res['Result']['PackageBanner'][0].PackageBannerButtons.length > 0) {
          setBanner(res['Result']['PackageBanner'][0].PackageBannerButtons);
          setHardlink1(res['Result']['PackageBanner'][0].PackageBannerButtons[0]);
          setHardlink2(res['Result']['PackageBanner'][0].PackageBannerButtons[1]);
        }

      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getPackageTicketCrossCampaign = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = '?prefix=' + crossCampaignName
    const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
    res
      .json()
      .then((res) => {
        setCrossPackageTicket(res['Result']['Packages']);
        setIsLoading(false)
        props.setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);

      });
  };

  const getPackageTicket = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
    res
      .json()
      .then((res) => {
        setPackageTicket(res['Result']['Packages']);
      })
      .catch((error) => {
        console.log(error);

      });
  };

  const getMoreTrill = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/MoreThill", requestOptions);
    res
      .json()
      .then((res) => {
        const packageAll = res["Result"]["Packages"]
        setMoreTrillItem(packageAll)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getFB = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/FB", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step 3 : ", res)
        setFbItem(res["Result"]["Packages"])
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getExtra = async () => {
    const requestOptions = {
      method: "GET",
      headers: { "accept-language": language }
    };
    const res = await fetch(apiLink + "/afcl/Package/Extra", requestOptions);
    res
      .json()
      .then((res) => {
        console.log("Step 4: ", res)
        let iList = res["Result"]["Packages"]
        setExtra(iList)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAllPackage() {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
    const res = await fetch(apiLink + '/afcl/Package' + path, requestOptions);
    res
      .json()
      .then((res) => {
        console.log('Package,', res['Result']);
        setAllPackage(res['Result'].Packages);
        const sort = res['Result'].PackageTypes.sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
        setPackageType(sort);
        setIsLoading(false)
        console.log("props", props)
        props.setIsLoading(false);
        console.log("isLoading", isLoading)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onCheckPackageToAddCart = () => {
    myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);
    if (myCart) {
      let condition
      let allTicket = []
      if (itCrossCampaign) {
        crossPackageTicket.forEach((item) => { allTicket.push(item) })
      }
      if (routePath == "") {
        if (myCart.length > 0 && packageTicket.length > 0) {
          condition = true
          packageTicket.forEach((item) => { allTicket.push(item) })
          if (!!moreTrillItem && moreTrillItem.length > 0) {
            moreTrillItem.forEach((x) => { allTicket.push(x) })
          }
          if (!!fbItem && fbItem.length > 0) {
            fbItem.forEach((x) => { allTicket.push(x) })
          }
          if (!!extra && extra.length > 0) {
            extra.forEach((x) => { allTicket.push(x) })
          }
        }
        console.log("allTicket", allTicket)
        // notEqualArray = myCart.filter((cart) => !packageTicket.some((api) =>
        //   cart.PackageCode === api.PackageCode) &&
        //   !moreTrillItem.some((api) => cart.PackageCode === api.PackageCode) &&
        //   !fbItem.some((api) => cart.PackageCode === api.PackageCode) &&
        //   !extra.some((api) => cart.PackageCode === api.PackageCode
        //   ));
      } else {
        condition = myCart.length > 0 && packageTicket.length > 0
      }
      if (condition) {
        let notEqualArray = []
        if (routePath == "") {
          notEqualArray = myCart.filter((cart) => !packageTicket.some((api) =>
            cart.PackageCode === api.PackageCode) &&
            !moreTrillItem.some((api) => cart.PackageCode === api.PackageCode) &&
            !fbItem.some((api) => cart.PackageCode === api.PackageCode) &&
            !extra.some((api) => cart.PackageCode === api.PackageCode
            ));
        } else {
          notEqualArray = myCart.filter((cart) => !packageTicket.some((api) => cart.PackageCode === api.PackageCode));
        }

        const EqualArray = myCart.filter((cart) => packageTicket.some((api) => cart.PackageCode === api.PackageCode));
        console.log("notEqualArray Before", notEqualArray)
        console.log("EqualArray Before", EqualArray)
        if (notEqualArray.length > 0) {
          setNotEqualArray(notEqualArray)
          setEqualArray(EqualArray)
          console.log("notEqualArray after", notEqualArray)
          console.log("EqualArray after", EqualArray)
          deletePackage()
          // setShowAlert(true)
          // setAlertText("delete_not_equal")
          return
        }
      }
    }
  }


  // const deletePackage = () => {
  //   sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
  //   setCart(equalArray)
  //   setShowAlert(false)
  //   window.location.reload();
  // }

  const deletePackage = async (packageCode) => {
    if (!!user && user.confirmationNo != "") {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'accept-language': language },
        body: JSON.stringify({
          UserCode: user.userCode,
          ConfirmationNo: user.confirmationNo
        }),
      };
      // /afcl/Package/Cart/RemovePackage
      const res = await fetch(apiLink + '/afcl/Package/Cart/RemovePackage', requestOptions);
      res
        .json()
        .then((res) => {
          const result = res['Success']
          if (result) {
            setShowAlert(false)
            // setIsNavigate(true)
            user.confirmationNo = ""
            sessionStorage.setItem('UserLogin', JSON.stringify(user));
            sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
            onCheckPackageToAddCart(packageCode)
            console.log('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
            navigate('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
      setShowAlert(false)
      // setIsNavigate(true)
      onCheckPackageToAddCart(packageCode)
      window.location.reload();
    }

  }

  const onClickBanner = (packageCode, itCrossPackage = false) => {
    myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    let itPackage
    if (itCrossPackage) {
      sessionStorage.setItem('itCrossCampaign', true);
      itPackage = crossPackageTicket
    } else {
      itPackage = packageTicket
    }

    let pk = itPackage.find((x) => x.PackageCode == packageCode);
    if (pk != null) {
      onCheckPackageToAddCart()
      onAddToCart(
        {
          PackageCode: pk.PackageCode,
          PackageName: pk.PackageName,
          ItemCode: '',
          ItemName: '',
          Qty: 1,
          Tax: 0,
          ItemPrice: pk.PackagePrice - pk.DiscountPrice,
          ItemDiscount: pk.DiscountPrice,
          PriceName: pk.PriceName,
          PriceUnit: '',
          TotalPrice: pk.PackagePrice - pk.DiscountPrice,
          VisitDate: null,
          VisitTime: '',
          VisitLocation: '',
          step: 1,
          status: 'normal',
          IsPromoDiscount: !pk.IsDiscount,
        },
        false,
      );
      console.log('Is data lanfing4')
    } else {
      console.log('not found');
    }


  };

  const onAddToCart = (item, isEdit) => {
    myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);
    console.log('BeforeAddCart, ', myCart);

    let aIndex = -1;

    if (myCart != null) {
      aIndex = myCart.findIndex((e) => {
        return e.PackageCode === item.PackageCode;
      });
    } else {
      myCart = [];
    }

    if (aIndex === -1) {
      // เพิ่ม Cart
      if (item.Qty > 0) {
        myCart = [...myCart, item];
        setCart(myCart);
      }
      console.log('AfterAddCart, ', myCart);
    } else {
      // แก้ไข Cart
      let preQty = myCart[aIndex].Qty;
      let newQty = preQty + 1;

      const editCart = myCart.map((e) => {
        if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
          return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' };
        }
        return e;
      });
      setCart(editCart);
    }
    sessionStorage.setItem('MyCart', JSON.stringify(cart));
    console.log("Cart", cart)
  };

  const getPackageSubTypeCode = (subTypeCode) => {
    // console.log("PackagePage ", subTypeCode);
    props.getPackageSubTypeCode(subTypeCode);
  };

  const onImageLoad = () => {
    setLoaded(true)
  }

  const { t } = useTranslation();

  return (
    <div className="page homepage" style={{ pointerEvents: inactiveCam || isLoading ? 'none' : 'inherit' }}>
      <ImageLoader src={bannerBG} transitionTime='0s'>
        {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
          <>
            {shouldShowLoader && !hasFailed && (
              <div className='banner-loading' style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '450px' }}>
                <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
              </div>
            )}
            {hasFailed && (
              <div className='AwesomeoLoader__failed'>
                Loading Fail :(
              </div>
            )}
            {hasLoaded && (
              <>
                <div style={{ padding: '10px', fontSize: isTabletOrMobile ? '8px' : '14px', background: '#0B3259', width: '100%', height: 'auto', minHeight: '50px', color: '#ffffff', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
                  {t('banner_header')}
                </div>
                <div className='banner' style={{ background: `url(${bannerBG}) no-repeat`, display: 'flex', position: 'relative' }}>

                  <>{/* href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} */}
                    <div className='banner-left'>
                      {pacBanner ?
                        <div className="button-linkf">
                          <a href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, false)}>
                            <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                          </a>
                        </div>
                        :
                        <div className="button-linkf">
                          {/* href={hardLink1.ButtonLinkUrl} target="_blank"  */}
                          {/* <a href={baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}> */}
                          <a href={baseLink + (itCrossCampaign ? '/' + crossCampaignName : routePath) + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, itCrossCampaign)}>
                            <button className="btn btn-darkblue">{hardLink1.ButtonDesc}</button>
                          </a>
                        </div>
                      }

                      {/* <div className='setwidthPic' style={{
                        width: '405px',
                        position: 'absolute',
                        top: '4%',
                        right: '0%',
                      }}>
                        {language == 'en' ?
                          <img src={revBadgeEN} onLoad={onImageLoad} style={{ width: '100%' }}></img>
                          :
                          <img src={revBadgeTH} onLoad={onImageLoad} style={{ width: '100%' }}></img>
                        }

                      </div> */}
                      <div className='setwidthfirst' style={{
                        width: '405px',
                        position: 'absolute',
                        top: '15%',
                        right: '0%',
                      }}>
                        <h2 className='pre-open' style={{ fontSize: '52px', color: '#001D41' }}>{bannerUrl.BannerName}</h2>
                      </div>
                      {/* <div className='setwidthsecond' style={{
                        position: 'absolute',
                        top: '40%',
                        width: '385px',
                        right: '3%',
                      }}>
                        <h2 className='aque' style={{ fontSize: '20px', color: '#004EA9' }}>
                          <span>
                            {t('banner_des2')}
                          </span>

                        </h2>
                      </div> */}
                      <div className='setwidth-last' style={{
                        position: 'absolute',
                        top: '64%',
                        width: '395px',
                        right: '2%',
                      }}>
                        <p className="weight-500" style={{ top: '65%', left: '45%', fontSize: '14px', color: '#000000' }}>
                          {bannerUrl.BannerDescription}
                          {/* {t('banner_underbutton')} */}
                        </p>
                      </div>
                    </div>
                    <div className='banner-right'>
                      <div className='surfboy'>
                        <a href={baseLink + (itCrossCampaign ? '/' + crossCampaignName : routePath) + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl, itCrossCampaign)}>
                          <ImageLoader src={bannerUrl.MediaUrl} transitionTime='0s'>
                            {({ hasLoaded, shouldShowLoader, hasFailed, src }) => (
                              <>
                                {shouldShowLoader && !hasFailed && (
                                  <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                                    <h2 style={{ color: 'rgb(112 112 112)' }}> Loading ... </h2>
                                  </div>
                                )}
                                {hasLoaded && (
                                  <>
                                    {pacBanner ?
                                      <>
                                        {/* <div className='packagesave-banner'>
                                        <div className="d-flex">
                                          <div> <h1 className='price-banner'>{(pacBanner.PackagePrice - pacBanner.DiscountPrice).toLocaleString()}</h1>  <h4 className='banner-discount'>{(pacBanner.PackagePrice).toLocaleString()} {t('thb')}</h4></div>
                                          <div style={{ margin: 'auto' }}><h1 className='banner-pricename'>{t('thb')}</h1><h1 className='banner-pax'>/ {t('pax')}</h1> </div>
                                        </div>
                                        <div> <h1 className='banner-save'>{t('save_banner')} {parseInt((pacBanner.DiscountPrice * 100 / pacBanner.PackagePrice).toLocaleString())}%</h1> </div>
                                      </div> */}
                                        <img className='img-banner' src={bannerUrl.MediaUrl} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                      </>
                                      :
                                      <>
                                        <div className='packagesave-banner'>
                                          <div className="d-flex">
                                            <div> <h1 className='price-banner'></h1>  <h4 className='banner-discount'></h4></div>
                                            <div style={{ margin: 'auto' }}><h1 className='banner-pax'></h1> </div>
                                          </div>
                                          <div> <h1 className='banner-save'></h1> </div>
                                        </div>
                                        <img className='img-banner' src={bannerUrl.MediaUrl} onLoad={onImageLoad} style={{ width: '770px' }}></img>
                                      </>

                                    }


                                  </>
                                )}
                              </>
                            )}
                          </ImageLoader>
                        </a>
                        {/* {pacBanner ?
                          <div className="button-links">
                            <a href={baseLink + '/ticket/' + hardLink1.ButtonLinkUrl + '/1'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-slider">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                          :
                          <div className="button-links">
                            <a onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                              <button className="btn btn-slider">{hardLink1.ButtonDesc}</button>
                            </a>
                          </div>
                        } */}

                      </div>
                    </div>
                  </>

                </div>
              </>
            )}
          </>
        )}
      </ImageLoader>

      <div id="PackageTypeName">
        {/* Slide Packages */}
        {packageType.map((e, index) => {
          if (e.PackageTypeCode !== packageTypeOther) {
            const packageList = allPackage.filter((p) => p.PackageTypeCode === e.PackageTypeCode);
            return <PackageType4 language={language} subTicket={{ moreTrillItem, fbItem, extra }} apiLink={apiLink} baseLink={baseLink} index={index} key={index} packageType={e} packageList={packageList} packageTicket={packageTicket} getPackageSubTypeCode={getPackageSubTypeCode} />;
          }
        })}
      </div>
      {allPackage.length > 0 && <Daypass packageList={allPackage.filter((p) => p.PackageTypeCode === packageTypeOther)} />}


      {isLoading && (
        <div className="spinner">
          <CircularProgress />
        </div>
      )}



      {/* <div className="section section-5 pb-5 text-center text-lg-start" style={{ marginTop: '-3rem' }}>
        <div className="container">
          <p className="weight-500 font-20">
            {t('Checkoutmore')}
            <br className="d-block d-lg-none" />
            <a href="#">
              <button type="button" className="btn btn-black btn-nf ms-lg-2 mt-3 mt-lg-0">
                {t('explore')}
              </button>
            </a>
          </p>
        </div>
      </div> */}

      {/* <Modal show={showAlert} className="col-12 cart-placeorder">
        <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
        <h5 className="text-center text-danger mb-5">{t(alertText)}</h5>
        {notEqualArray && notEqualArray.map((item, index) => ((
          <h5 className="text-center" key={index}>{item.PackageName} : {t('price')} {item.TotalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</h5>
        )))}
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <button className="btn" style={{ height: '40px', background: '#d63737', borderRadius: '5px', fontWeight: '700', border: '0px', color: '#fff' }} onClick={deletePackage}>{t('delete')}</button>
        </div>
      </Modal> */}
    </div >
  );
};

export default LandingPage4
