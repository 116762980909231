import React, { useEffect, useState } from 'react';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import TermIcon from '../../component/img/info.png';
import noImg from '../../component/img/no_image.png';
import { ReactComponent as Verified } from '../../component/img/check-green.svg';
import { useMediaQuery } from 'react-responsive'
import { packageTypeTicket } from '../../constants/DataPublic';
import { useGlobalState } from '../../global';

export const AddSubTicket = (props) => {
  const { currentStep } = props
  const { picturePath } = props
  const { packageTypeTicket } = props
  const { itemList } = props
  const { itemQty } = props
  const { netPrice } = props
  const { selectedI } = props
  const { subTotalI } = props
  const { subTotalIExVat } = props
  const { editI } = props
  const [routePath, setRoutePath] = useGlobalState('route');
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 991px)'
  })

  const setItemQty = (item) => {
    props.setItemQty(item);
  };

  const addToCart = (item, isEdit) => {
    props.addToCart(item, isEdit);
  };

  const setSelectedI = (item) => {
    props.setSelectedI(item);
  };

  const { t } = useTranslation();


  return (
    <>
      {/** To Flip */}
      <div className="col-lg-6 ">
        <img src={picturePath} className="img-fluid w-100 d-block d-lg-none mb-3" />
        <div className="box box-border mb-0">
          <div className="box-body ">
            <div className="row">
              <div className="col-7 desc">
                {itemList.IsAddonDiscount && (
                  <div className="d-none d-lg-block">
                    <span className="bubble mb-3">
                      3rd Ticket at
                      <br />
                      50% Discount
                    </span>
                  </div>
                )}
                <div className="d-block d-lg-none">
                  <p>{/* <Markup content={itemList.PackageDescription} /> */}</p>
                </div>
              </div>
              {/* {itemList.PackageTypeCode === packageTypeTicket && ( */}
              {!isDesktopOrLaptop && (
                <div className="col-6 desc">
                  <p className="font-14 mb-2">
                    <strong>{t('overview')} :</strong>
                  </p>
                  {itemList.PackageItemLists.map((e, i) => {
                    return (
                      <p>
                        <Markup key={i} content={e.ItemType == 'Ticket' ? '● ' + e.Qty + ' ' + e.ItemName : '+ ' + e.Qty + ' ' + e.ItemName} />
                      </p>
                    );
                  })}
                  {/* <Markup content={itemList.PackageDescription} /> */}
                </div>
              )}

              {/* // )} */}
              {itemList.IsDiscount && (
                <div className="col-5 text-end">
                  <p className="font-20 weight-700 color-orange mb-0 lh-1">
                    {(itemList.isExCludeVat ? (itemList.ItemPriceExVat - itemList.ItemDiscountExVat) : netPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                  </p>
                  <p className="oldPrice">
                    {(itemList.isExCludeVat ? itemList.ItemPriceExVat : itemList.PackagePrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                  </p>
                  {itemList.IsAddonDiscount && (
                    <div className="d-block d-lg-none">
                      <span className="bubble mb-3">
                        3rd Ticket at
                        <br />
                        50% Discount
                      </span>
                    </div>
                  )}
                </div>
              )}
              {!itemList.IsDiscount && (
                <div className="col-5 text-end">
                  <p className="font-20 weight-700 mb-3 lh-1">
                    {(itemList.isExCludeVat ? (itemList.ItemPriceExVat - itemList.ItemDiscountExVat) : netPrice).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                  </p>
                </div>
              )}
              <div className="col-12">
                <p className="color-orange font-10 mb-0 weight-700">
                  <em>{itemList.PackageDescription}</em>
                  {/* <em>{t('ticket_expire')} </em> */}
                  {/* <em>{itemList.PackageExpireMessage}</em> */}
                </p>
              </div>
              <div className="col-12">
                <hr className="mt-2 mb-3" />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-6 desc">
                <p className="font-10 mb-0">{t('quantity')}</p>
              </div>
              <div className="col-6 text-end">
                <div className="input-group ms-auto">
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className={`btn  btn-number minus ${selectedI}`}
                      data-type="minus"
                      data-field="quant[1]"
                      onClick={(e) => {
                        if (itemQty > 1) setItemQty((itemQty) => itemQty - 1);
                        else setItemQty(1);
                      }}
                    >
                      <span className="material-icons">remove</span>
                    </button>
                  </span>
                  <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: '-3px' }} value={itemQty} defaultValue={1} min={1} disabled />
                  <span className="input-group-btn">
                    <button
                      type="button"
                      className={`btn btn-number plus ${selectedI}`}
                      data-type="plus"
                      data-field="quant[1]"
                      onClick={(e) => {
                        setItemQty((itemQty) => itemQty + 1);
                      }}
                    >
                      <span className="material-icons">add</span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-body ">
          <div className="row align-items-center">
            <div className="col-6 pe-0">
              <p className="font-14 weight-700 mb-0">{t('subtotal')}</p>
            </div>
            <div className="col-6 text-end ps-0">
              <p className="font-24 weight-900 color-orange mb-0">
                {(itemList.isExCludeVat ? subTotalIExVat : subTotalI).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className="font-18">{t('thb')}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-block d-lg-none mb-4">
          {/* {routePath == '/16two' ? (
            <>
              <p className="d-flex font-14 mb-2 weight-600 text-decoration-underline" >
                <a href="https://columbiapicturesaquaverse.com/terms-and-conditions/" target="_blank">
                  <img src={TermIcon} className="TermIcon" />
                  {t('terms_conditions')}{' '}
                </a>
              </p>
            </>
          ) : (
            <> */}
          <p
            className="d-flex font-14 mb-2 weight-600 text-decoration-underline"
            data-bs-toggle="collapse"
            href={'#term' + itemList.PackageCode}
            role="button"
            aria-expanded="false"
            aria-controls="term1"
          >
            <img src={TermIcon} className="TermIcon" />
            {t('terms_conditions')}{' '}
          </p>

          <div className="collapse" id={'term' + itemList.PackageCode}>
            <div className="card card-body text-left">
              <Markup content={itemList.PackageTermAndCondition} />
            </div>
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>
            {itemList.isExCludeVat && '*' + t('price_ex_vat')}
          </p>
        </div>
        {selectedI === '' && (
          <div className="col-12 d-">
            <button
              className="btn btn-main"
              type="button"
              onClick={() => {
                addToCart(
                  {
                    PackageCode: itemList.PackageCode,
                    PackageName: itemList.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: itemQty,
                    Tax: 0,
                    ItemPrice: netPrice,
                    ItemDiscount: itemList.IsDiscount ? itemList.DiscountPrice : 0,
                    PriceName: itemList.PriceName,
                    PriceUnit: '',
                    TotalPrice: itemQty * netPrice,
                    VisitDate: null,
                    VisitTime: '',
                    VisitLocation: '',
                    step: currentStep,
                    status: 'normal',
                    ItemPriceExVat: (itemList.ItemPriceExVat - itemList.ItemDiscountExVat),
                    TotalPriceExVat: itemQty * (itemList.ItemPriceExVat - itemList.ItemDiscountExVat),
                    ItemDiscountExVat: itemList.IsDiscount ? itemList.ItemDiscountExVat : 0,
                    IsPromoDiscount: !itemList.IsDiscount,
                  },
                  editI,
                );
                if (itemQty > 0) setSelectedI('hidden');
              }}
            >
              {t('addtocart')}
            </button>
          </div>
        )}
        {selectedI === 'hidden' && (
          <span className="btn">
            <Verified className="img- me-3" />
            {t('addtocart')}{' '}
          </span>
        )}
      </div>
    </>
  );
};
