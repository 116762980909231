import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Modal } from 'react-bootstrap';

import { ReactComponent as Verified } from "../../component/img/check-green.svg";
import { baseLink, apiLink, incTax } from '../../constants/DataPublic'

import logo from "../img/logo.png";
import { ReactComponent as Close } from "../img/close.svg";
import { ReactComponent as Success } from "../img/Success.svg";
import CartList from './CartList';
import { useGlobalState } from '../../global'
import CalVatExclude from '../../customHook/CalVatExclude';

const Cart = (props) => {

  const { packageCode, currentStep, language, cart, needCheckout, paymentGateway, publicKey, notEqualPackage } = props

  const [routePath, setRoutePath] = useGlobalState('route');
  const [businessPolicy] = useGlobalState('businessPolicy');
  const [promo, setPromo] = useGlobalState('discountPromo');
  const [promoCode, setPromoCode] = useGlobalState('promoCode');
  const [havePro, setHavePro] = useGlobalState('havePro');
  const [disabledPromo, setDisabledPromo] = useGlobalState('disabledPromo');
  const [subTotal, setSubTotal] = useState(0)
  const [subPromo, setSubPromo] = useState(0);
  // const [promo, setPromo] = useState(0)
  // const [promoCode, setPromoCode] = useState(0)
  const [total, setTotal] = useState(0)
  const [saving, setSaving] = useState(0)
  const [savingDis, setSavingDis] = useState(0);
  const [canPay, setCanPay] = useState(true)
  const [subTotalExVat, setSubTotalExVat] = useState(0);

  const navigate = useNavigate();

  let myCart = sessionStorage.getItem('MyCart')
  myCart = JSON.parse(myCart)

  let user = sessionStorage.getItem('UserLogin')
  user = JSON.parse(user)

  let promotion = sessionStorage.getItem('Promo');
  promotion = JSON.parse(promotion);

  useEffect(() => {
    //Cal Vat Exclude
    if (businessPolicy && cart != null) {
      if (cart.length > 0) {
        cart.forEach((pk) => {
          CalVatExclude(pk, businessPolicy)
        })
      }
    }
  }, [businessPolicy, cart])

  useEffect(() => {
    let subT = 0
    let savT = 0
    let subP = 0;
    let subTExVat = 0;
    let savTExVat = 0;
    if (cart.length > 0) {
      cart.forEach(e => {
        if (businessPolicy) {
          if(e.IsPromoDiscount){  
            if (promotion.promoType === "AMT") {
              subP = promotion.discountPromo
            }else{
              subP = (subP + (e.ItemPrice * e.Qty)*(promotion.discountPromo / 100))
            }
          }
          // subT = !businessPolicy.IsIncludeVat ? (subT + (e.ItemPriceExVat * e.Qty)) : (subT + (e.ItemPrice * e.Qty))
          // savT = !businessPolicy.IsIncludeVat ? (savT + (e.ItemDiscountExVat * e.Qty)) : (savT + (e.ItemDiscount * e.Qty))

          subT = (subT + (e.ItemPrice * e.Qty))
          savT = (savT + (e.ItemDiscount * e.Qty))

          subTExVat = (subTExVat + (e.ItemPriceExVat * e.Qty))
          savTExVat = (savTExVat + (e.ItemDiscountExVat * e.Qty))
        }
      })
      setCanPay(true)
    } else {
      setCanPay(false)
    }
    setSubTotal(subT)
    setSubPromo(subP);
    setSubTotalExVat(subTExVat);
    if (businessPolicy) {
      setSaving(!businessPolicy.IsIncludeVat ? savTExVat+subP : savT+subP)
      setSavingDis(!businessPolicy.IsIncludeVat ? savTExVat : savT);
    }
    // setPromo(promotion.discountPromo)
    // setPromoCode(promotion.promoCode)
    // setHavePro(promotion.havePro)
    // setTotal(subTotal - promotion.discountPromo);
    console.log("cart", cart)
    console.log("savT", savT)
    console.log("subT", subT)
    console.log("savTExVat", savTExVat)
  }, [cart])


  useEffect(() => {
    let subP = 0;
    if (promotion.promoType === 'AMT') {
      setTotal(subTotal - promotion.discountPromo);
      setSaving(savingDis + promotion.discountPromo)
      setPromo(promotion.discountPromo)
    } else {
      if (cart != null) {
        if (cart.length > 0) {
          cart.forEach((e) => {
            if(e.IsPromoDiscount){  
              if (promotion.promoType === "AMT") {
                subP = promotion.discountPromo
              }else{
                subP = (subP + (e.ItemPrice * e.Qty)*(promotion.discountPromo / 100))
              }
            }
          });
        }
      }
      // let discount = subTotal * (promotion.discountPromo / 100)
      let discount = subP;
      setTotal(subTotal - discount);
      // setSaving(savingDis + promotion.discountPromo)
      setSaving(savingDis + discount)
      setPromo(discount)
    }
    setHavePro(promotion.havePro)
    setPromoCode(promotion.promoCode)
  }, [subTotal])

  const onEditCart = (pCode, iCode) => {
    props.onEditCart(pCode, iCode)
  }

  const onRemoveCart = (pCode, iCode) => {
    props.onRemoveCart(pCode, iCode)
  }

  const onAddToCart = (itemCart, isEdit) => {
    props.onAddToCart(itemCart, isEdit)
  }

  const saveCart = async (e, showP) => {
    e.preventDefault();
    if (user !== null && (user.isLogin || user.type == "guest")) {
      let orderItem = []
      myCart.map((e) => {
        return orderItem.push({
          PackageCode: e.PackageCode,
          ItemCode: e.ItemCode,
          Qty: e.Qty,
          // VisitDate: e.VisitDate === null ? null : e.VisitDate.substr(5, 2) + / + e.VisitDate.substr(8, 2) + / + e.VisitDate.substr(0, 4),
          VisitDate: e.VisitDate === null ? null : e.VisitDate,
          VisitTime: e.VisitTime,
          VisitLocation: e.VisitLocation
        })
      })

      let bodytext = {
        ConfirmationNo: user.confirmationNo,
        ChannelCode: user.channelCode,
        UserId: user.userId,
        UserCode: user.userCode,
        PromotionCode: promoCode,
        OrderItems: orderItem,
        Prefix: routePath.replace('/', '')
      }

      // Email: user.email,
      //   FirstName: user.fName,
      //   LastName: user.lName,
      //   PhoneNo: user.phoneNo,
      //   IDCardNo: user.idCardNo,
      //   telCode: user.telCode,
      //   DeviceType:"",
      //   PushKey:"",
      //   DeviceKey:"",

      console.log("SaveOrder Body: ", bodytext)
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", 'accept-language': language },
        body: JSON.stringify(bodytext)
      };
      console.log(requestOptions)

      await fetch(apiLink + '/afcl/Package/SaveOrder/V2', requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          const items = data;
          console.log("Save Cart: ", items);
          if (items["Success"]) {
            user.confirmationNo = items["Result"].ConfirmationNo
            user.totalPrice = total
            sessionStorage.setItem('UserLogin', JSON.stringify(user))
            setShowPopup(showP)

            if (!showP) {
              navigate('/payment');
              // if (paymentGateway === 'P003') {
              //   navigate('/payment');
              // } else if (paymentGateway === 'P004') {
              //   navigate('/paymentOmise', {
              //     state: {
              //       publicKey: publicKey,
              //     }
              //   });
              // }
            }

          } else {
            setAlertText(items["Message"])
            setShowAlert(true)
          }
        })
        .catch((error) => {
          console.log("Error: ", error);
          // alert('Error: API /afcl/Package/SaveOrder ', error)
          setAlertText(error)
          setShowAlert(true)
        })
    } else {
      navigate("/signin")
    }
  }

  const confirmOrder = async (e) => {
    user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)
    var dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': 5, 'option': user.paymentMethod }
        }
      }
    });
    await saveCart(e, false)
  }

  const verifyPromoCode = async () => {
    const requestOptions = {
      method: 'GET',
      headers: { 'accept-language': language },
    };
    let toPro = promoCode == '' ? null : promoCode
    let prefix = routePath != '' ? '?Prefix=' + routePath.replace('/', '') : ''
    await fetch(apiLink + '/afcl/Package/VerifyPromotionCode/' + toPro + prefix, requestOptions)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        const items = data;
        let subP = 0;
        if (items.Status) {
          if (items.DiscountType === "AMT") {
            let totalDiscount = subTotal - items.DiscountAmt
            setPromo(items.DiscountAmt)
            setTotal(totalDiscount);
            setSaving(savingDis + items.DiscountAmt);
            sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
          } else {
            if (cart != null) {
              if (cart.length > 0) {
                cart.forEach((e) => {
                  if(e.IsPromoDiscount){  
                    subP = (subP + (e.ItemPrice * e.Qty)*(items.DiscountAmt / 100))
                  }
                });
              }
            }
            // let discount = subTotal * (items.DiscountAmt / 100)
            let discount = subP
            let totalDiscount = subTotal - discount
            setPromo(discount);
            setSubPromo(subP);
            setTotal(totalDiscount);
            setSaving(savingDis + discount);
            sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: items.DiscountAmt, promoCode: promoCode, havePro: true, promoType: items.DiscountType }))
          }
          setHavePro(true)
        } else {
          setSaving(savingDis)
          setTotal(subTotal);
          setPromoCode(null)
          setPromo(null)
          setAlertText('promo_notavai');
          setShowAlert(true);
          setHavePro(false)
          sessionStorage.setItem('Promo', JSON.stringify({ discountPromo: '', promoCode: '', havePro: false, promoType: '' }))
        }
        console.log('items,', items)
      })
      .catch((error) => {
        console.log('Error: ', error);
        setAlertText(error);
        setShowAlert(true);
      });


    // let totalPrice = subTotal - promo;
    // let totalSaving = saving + promo;
    // setTotal(totalPrice);
    // setSaving(totalSaving);
    // console.log('saving', saving);
    // console.log('promo', promo);
    // console.log('totalPrice', totalPrice);
    // console.log('Promo Code', totalSaving);
    // console.log('user.confirmationNo', user.confirmationNo);
  };

  const setCartUpdate = (cartUpdate) => {
    console.log("cartUpdate", cartUpdate);
    props.setCartUpdate(cartUpdate)
  }

  /** Register Success. **/
  const [showPopup, setShowPopup] = useState(false);
  const CloseSignupSuccess = () => setShowPopup(false);
  const ShowSignupSuccess = () => setShowPopup(true);

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");

  // ภาษา
  const { t } = useTranslation();

  return (
    //START RIGHT CONTENT
    <div className="cart-mobile mb-5" >
      {/* คูปอง */}
      {!disabledPromo && (
        <>
          {!havePro && (
            <div className="box box-border mb-3 d-none d-lg-block">
              <div className="box-body">
                <h5>{t('have_promo_code')}</h5>
                {!canPay ?
                  <input className="form-control mb-3" type="text" placeholder={t('enter_promo_code')} readOnly />
                  :
                  <input className="form-control mb-3" type="text" placeholder={t('enter_promo_code')} onChange={(e) => { setPromoCode(e.target.value) }} />
                }

                <button className="btn btn-dark" type="button" onClick={verifyPromoCode}>{t('verify')}</button>
              </div>
            </div>
          )}

          {havePro && (
            <div className="box box-border mb-3 d-none d-lg-block">
              <div className="box-body">
                <h5>{t('promo_code')}</h5>
                <div className="row align-items-center pt-2">
                  <div className="col-lg-6">
                    <p className="color-grey font-18 weight-600 mb-0">{promoCode}</p>
                  </div>
                  <div className="col-lg-6 text-end">
                    <p className="color-green font-18 weight-600 mb-0">{t('verified')} <Verified className="img-" /></p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {/* คูปอง */}


      <div className="box box-border">
        {!needCheckout && (
          <div>
            <div className="box-cart-header">
              <div className="cart-title">
                <span className="icon material-icons">shopping_cart</span><span>{t('cart')}</span>
              </div>
            </div>

            <div className="box-body ">
              <div className="row">
                <div className="col-6">
                  <p className="font-12 weight-400 color-grey mb-0">{t('item')}</p>
                </div>
                <div className="col-6">
                  <p className="font-12 weight-400 color-grey text-end mb-0">{t('price')}</p>
                </div>
                <div className="col-12"><hr /></div>
              </div>
              {cart && businessPolicy && (
                cart.map((e, index) => {
                  let checkLast = false
                  if (e.step == 1 && cart.some(x => x.step != 1) && cart.filter(x => x.step == 1).length == 1) {
                    checkLast = true
                  }
                  return (
                    <CartList IsIncludeVat={businessPolicy.IsIncludeVat} currentStep={currentStep} checkLastItem={checkLast} cartItem={e} setCartUpdate={setCartUpdate} onRemoveCart={onRemoveCart} onAddToCart={onAddToCart} />
                  )
                })
              )}


              <div className="d-block d-lg-none">
                <br />
                {!disabledPromo && (
                  <>
                    {!havePro ?
                      <div className="box mb- py-3 px-3" style={{ backgroundColor: '#DEDEDE' }}>
                        <div className="row">
                          <div className="col-12 ">
                            <h5 className="font-14">{t('have_promo_code')}</h5>
                          </div>
                          <div className="col-8 ">
                            {!canPay ?
                              <input className="form-control" type="text" placeholder={t('enter_promo_code')} readOnly />
                              :
                              <input className="form-control" type="text" placeholder={t('enter_promo_code')} value={promoCode} onChange={(e) => { setPromoCode(e.target.value) }} />
                            }
                          </div>
                          <div className="col-4 ps-0 text-end">
                            <button className="btn btn-dark" type="button" onClick={verifyPromoCode}>{t('verify')}</button>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="box mb- py-3 px-3" style={{ backgroundColor: '#DEDEDE' }}>
                        <div className="row">
                          <div className="col-12 ">
                            <h5 className="font-20 text-start">Promo Code</h5>
                          </div>
                          <div className="col-8">
                            <p className="color-grey font-18 weight-600 mb-0 text-start">{promoCode}</p>
                          </div>
                          <div className="col-4 ps-0 text-end">
                            <p className="color-green font-18 weight-600 mb-0">{t('verified')} <Verified className="img-" /></p>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="box-body bg-grey2" style={{ minHeight: 169 }}>
          <div className="row align-items-center">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('subtotal')}</p>
            </div>
            <div className="col-6 text-end">
              <p className="font-18 weight-700 mb-0">
                {businessPolicy && (
                  <>
                    {(!businessPolicy.IsIncludeVat ? subTotalExVat : subTotal).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                  </>
                )}
              </p>
            </div>
            <div className="col-12"><hr /></div>
          </div>
          <div className="row align-items-center ">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('promotion')}</p>
            </div>
            <div className="col-6 text-end">
              <p className="font-18 weight-700 mb-0">
                {promotion.promoType == 'AMT' ?
                  <>
                    {promotion.discountPromo && (
                      <>
                        {'- ' + promotion.discountPromo.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                      </>
                    )}
                  </>
                  :
                  <>
                    {promotion.discountPromo && (
                      <>
                        {subPromo == 0 ?
                          <>

                          </>
                          :
                          <>
                            {/* {'- ' + (subTotal * (promotion.discountPromo / 100)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')} */}
                            {'- ' + (subPromo).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                          </>
                        }

                      </>
                    )}
                  </>
                }
              </p>
            </div>
            <div className="col-sm-12"><hr /></div>
          </div>
          {businessPolicy && !businessPolicy.IsIncludeVat && (
            <div className="row align-items-center">
              <div className="col-6">
                <p className="font-14 weight-400 color-grey mb-0">{t('incl_tax')} ({incTax}%)</p>
              </div>
              <div className="col-6 text-end">
                <p className="font-18 weight-700 mb-0">
                  {(total * (businessPolicy.VatRate / (100 + businessPolicy.VatRate))).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}
                </p>
              </div>
              <div className="col-12">
                <hr />
              </div>
            </div>
          )}
        </div>
        <div className="box-body">
          <div className="row align-items-center">
            <div className="col-6 pe-0">
              <p className="font-24 weight-700 mb-0">{t('total')} <span className="font-10 color-grey weight-400">{t('incl_tax')} ({incTax}%)</span></p>
            </div>
            <div className="col-6 text-end ps-0">
              <p className="font-24 weight-900 color-orange mb-0">{total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} <span className="font-18">{t('thb')}</span></p>
            </div>
          </div>
        </div>
        <div className="box-body pb-4 bg-grey2">
          <div className="row align-items-center mb-4">
            <div className="col-6">
              <p className="font-14 weight-400 color-grey mb-0">{t('total_saving')}</p>
            </div>
            <div className="col-6 text-end">
              {/* <p className="font-18 weight-700 mb-0">{saving.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')}</p> */}
              <p className="font-18 weight-700 mb-0">{parseFloat(saving).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</p>
            </div>
          </div>
          <div className="row align-items-center">
            {currentStep < 5 && (
              <>
                <div className="col-12">
                  <a href={baseLink + routePath + "/ticket/" + packageCode + "/5"}><button className="btn btn-main" type="button">{t('checkout')}</button></a>
                </div>
                <div className="col-12 relative"><hr /><span className="or">{t('or')}</span></div>
                <div className="col-12">
                  <a href={baseLink + routePath + "/ticket/" + packageCode + "/" + (currentStep + 1)}><button className="btn btn-blue mb-3" type="button" >{t('next')}</button></a>
                </div>
              </>
            )}
            {!needCheckout && (
              <div>
                <div className="col-12">
                  <button className={`btn btn-main mb-3 ${props.editGuest ? 'inactive' : ''}`} type="button" onClick={() => props.setNeedCheckout(true)} >{t('gotocheckout')}</button>
                </div>
                {/* {routePath !== "" && (
                <div className="col-12">
                  <button className="btn btn-green" type="button" onClick={(e) => saveCart(e, true)}>
                    {t('save_cart')}
                  </button>
                </div>
              )} */}
              </div>
            )}

            {needCheckout && (
              <div className="col-12">
                <button className={`btn btn-main mb-3 ${props.editGuest ? 'inactive' : ''}`} type="button" onClick={confirmOrder} disabled={!canPay}>{t('confirm_payment')}</button>
              </div>
            )}

            <Modal show={showPopup} className="col-12 cart-savecart">
              <a href="#" className="close"  ><Close className="popup-close" onClick={CloseSignupSuccess} /></a>
              <div className="logosignin"> <img src={logo} className="logo-popup" /></div>
              <div className="text-center mb-3">
                <Success className="img-popup" />
              </div>
              <p className="text-center text-color">{t('save_complete')}</p>
            </Modal>

            <Modal show={showAlert} className="col-12 cart-placeorder">
              <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
              <h5 className="text-center text-danger">{t(alertText)}</h5>
            </Modal>
          </div>
        </div>
      </div>



    </div>
    //END RIGHT CONTENT
  )
}
export default Cart;