import './landingChina.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import { apiLink, baseLink } from '../constants/DataPublic';
import { useMediaQuery } from 'react-responsive'
import { Modal } from 'react-bootstrap';
import { ReactComponent as Close } from "../component/img/close.svg";
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { useGlobalState } from '../global'
import topCombineCN from './../component/img/china/top-combine.jpg'
import topCombineCN2 from './../component/img/china/top-combine2.jpg'
// import topCombineEN from './../component/img/songkran/top-combine.jpg'
import topCombineTH from './../component/img/songkran/top-th.jpg'
import topMobileEN from './../component/img/songkran/top-mobile.jpg'
import topPcTH from './../component/img/songkran/top-mobile-th.jpg'
import floatingEN from './../component/img/songkran/floating-cta-with-btn.png'
import floatingTH from './../component/img/songkran/floating-cta-with-btn-th.png'
import floatingMobileEN from './../component/img/songkran/floating-cta-mobile.png'
import floatingMobileTH from './../component/img/songkran/floating-cta-mobile-th.png'
import btnMiniConcert from './../component/img/songkran/btn-mini-concert.png'
import btnMiniConcertTH from './../component/img/songkran/btn-mini-concert-th.png'

//Component
const LandingChina = (props) => {
    const { language } = props;
    const [routePath, setRoutePath] = useGlobalState('route');
    const [loadCampaign, setLoadCampaign] = useGlobalState('loadCampaign');
    const [inactiveCam, setInactiveCam] = useGlobalState('inactiveCam');
    const [hardLink1, setHardlink1] = useState({});
    const [packageTicket, setPackageTicket] = useState([]);
    const [cart, setCart] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [equalArray, setEqualArray] = useState([]);
    const [notEqualArray, setNotEqualArray] = useState([]);
    const [isNavigate, setIsNavigate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 767px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 767px)' })

    let myCart = sessionStorage.getItem('MyCart');
    myCart = JSON.parse(myCart);

    let user = sessionStorage.getItem('UserLogin')
    user = JSON.parse(user)

    useEffect(() => {
        if (cart.length > 0) sessionStorage.setItem('MyCart', JSON.stringify(cart));
    }, [cart]);


    useEffect(async () => {
        getPackageTicket();
        getBanner()
        console.log("loadCampaign", loadCampaign)
        sessionStorage.removeItem('itCrossCampaign');
    }, [language]);


    async function getBanner() {
        setIsLoading(true)
        const requestOptions = {
            method: 'GET',
            headers: { 'accept-language': language },
        };        
        let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
        const res = await fetch(apiLink + '/afcl/Package/Banner' + path, requestOptions);
        res
            .json()
            .then((res) => {
                let sortBanner = res['Result']['PackageBanner'].sort((a, b) => (a.SeqNo > b.SeqNo ? 1 : -1));
                setHardlink1(sortBanner[0].PackageBannerButtons[0]);
                setIsLoading(false)
                props.setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getPackageTicket = async () => {
        setIsLoading(true)
        const requestOptions = {
            method: 'GET',
            headers: { 'accept-language': language },
        };
        let path = routePath != '' ? '?prefix=' + routePath.replace('/', '') : ''
        const res = await fetch(apiLink + '/afcl/Package/Ticket' + path, requestOptions);
        res
            .json()
            .then((res) => {
                setPackageTicket(res['Result']['Packages']);
                setIsLoading(false)
                props.setIsLoading(false)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onCheckPackageToAddCart = (packageCode) => {
        let pk = packageTicket.find((x) => x.PackageCode.toLocaleLowerCase() == packageCode.toLocaleLowerCase());
        if (pk != null) {
            onAddToCart(
                {
                    PackageCode: pk.PackageCode,
                    PackageName: pk.PackageName,
                    ItemCode: '',
                    ItemName: '',
                    Qty: 1,
                    Tax: 0,
                    ItemPrice: pk.PackagePrice - pk.DiscountPrice,
                    ItemDiscount: pk.DiscountPrice,
                    PriceName: pk.PriceName,
                    PriceUnit: '',
                    TotalPrice: pk.PackagePrice - pk.DiscountPrice,
                    VisitDate: null,
                    VisitTime: '',
                    VisitLocation: '',
                    step: 1,
                    status: 'normal',
                    IsPromoDiscount: !pk.IsDiscount,
                },
                false,
            );
        } else {
        }
    }

    const onClickBanner = (packageCode) => {
        myCart = sessionStorage.getItem('MyCart')
        myCart = JSON.parse(myCart)

        //เช็ค package เมื่อเปลี่ยน channel แล้วเลือกสินค้า จะ alert ให้ลบ package ที่ไม่มีใน channel นั้น --------------------------
        if (myCart || myCart != null) {
            const notEqualArray = myCart.filter((cart) => !packageTicket.some((api) => cart.PackageCode === api.PackageCode));
            const EqualArray = myCart.filter((cart) => packageTicket.some((api) => cart.PackageCode === api.PackageCode));

            if (notEqualArray.length > 0) {
                setNotEqualArray(notEqualArray)
                setEqualArray(EqualArray)
                deletePackage(packageCode)
                // setShowAlert(true)
                setAlertText("delete_not_equal")
                return
            } else {
                console.log("deletePackageSongkran", packageCode)
                setIsNavigate(true)
                onCheckPackageToAddCart(packageCode)
            }
        } else {
            console.log("deletePackageSongkran", packageCode)
            setIsNavigate(true)
            onCheckPackageToAddCart(packageCode)
        }
    };

    // const deletePackage = (packageCode) => {
    //     sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
    //     setShowAlert(false)
    //     setIsNavigate(true)
    //     onCheckPackageToAddCart(packageCode)
    //     window.location.reload();
    // }


    const deletePackage = async (packageCode) => {

        if (!!user && user.confirmationNo != "") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'accept-language': language },
                body: JSON.stringify({
                    UserCode: user.userCode,
                    ConfirmationNo: user.confirmationNo
                }),
            };
            // /afcl/Package/Cart/RemovePackage
            const res = await fetch(apiLink + '/afcl/Package/Cart/RemovePackage', requestOptions);
            res
                .json()
                .then((res) => {
                    const result = res['Success']
                    if (result) {
                        setShowAlert(false)
                        setIsNavigate(true)
                        user.confirmationNo = ""
                        sessionStorage.setItem('UserLogin', JSON.stringify(user));
                        sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
                        onCheckPackageToAddCart(packageCode)
                        console.log('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
                        navigate('/ticket/' + hardLink1.ButtonLinkUrl + '/1')
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            sessionStorage.setItem('MyCart', JSON.stringify(equalArray));
            setShowAlert(false)
            setIsNavigate(true)
            onCheckPackageToAddCart(packageCode)
            window.location.reload();
        }

    }



    const onAddToCart = (item, isEdit) => {
        myCart = sessionStorage.getItem('MyCart');
        myCart = JSON.parse(myCart);

        let aIndex = -1;

        if (myCart != null) {
            aIndex = myCart.findIndex((e) => {
                return e.PackageCode === item.PackageCode;
            });
        } else {
            myCart = [];
        }

        if (aIndex === -1) {
            // เพิ่ม Cart
            if (item.Qty > 0) {
                myCart = [...myCart, item];
                setCart(myCart);
            }
        } else {
            // แก้ไข Cart
            let preQty = myCart[aIndex].Qty;
            let newQty = preQty + 1;

            const editCart = myCart.map((e) => {
                if (e.PackageCode === item.PackageCode && e.ItemCode === item.ItemCode) {
                    return { ...e, Qty: newQty, VisitDate: item.VisitDate, VisitLocation: item.VisitLocation, VisitTime: item.VisitTime, status: 'normal' };
                }
                return e;
            });
            setCart(editCart);
        }
    };

    const getMobileOS = () => {
        const ua = navigator.userAgent
        if (/android/i.test(ua)) {
            return "Android"
        }
        else if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
            return "iOS"
        }
        return "Other"
    }

    const { t } = useTranslation();

    return (
        <div className="page homepage" style={{ pointerEvents: inactiveCam ? 'none' : 'inherit' }}>
            <a href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + (getMobileOS().toLocaleLowerCase() === 'other' ? "/1" : "/5") : '##'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                <div className="section ">
                    <img className="img-fluid w-100 d-md-block d-none" src={topCombineCN2} />
                    <img className="img-fluid w-100 d-block d-md-none" src={topCombineCN2} />
                </div>


                {/* <div id="content" className="pb-5">
                    <img src={require('./../component/img/songkran/palm-leaves-1.png')} className="img-fluid palm-leaves1" />
                    <img src={require('./../component/img/songkran/palm-leaves-2.png')} className="img-fluid palm-leaves2 d-md-none d-block" />
                    <div className="row mx-0">
                        <div className="col-md-8 offset-md-2">
                            <div className="row mx-0">

                                <div className="col-md-2 col-4 align-self-center d-flex justify-content-center">
                                    <img src={require('./../component/img/songkran/1.png')} className="img-fluid" />
                                </div>
                                <div className="col-md-10 col-8">
                                    <p className="py-2">
                                        {t('songkran_desc1')}
                                    </p>
                                </div>

                                <div className="col-md-10 col-8">
                                    <p className="py-2">
                                        {t('songkran_desc2')}
                                    </p>
                                </div>
                                <div className="col-md-2 col-4 align-self-center d-flex justify-content-center">
                                    <img src={require('./../component/img/songkran/2.png')} className="img-fluid" />
                                </div>

                                <div className="col-md-2 col-4 align-self-center d-flex justify-content-center">
                                    <img src={require('./../component/img/songkran/palm-leaves-2.png')} className="img-fluid palm-leaves4 d-md-none d-block" />
                                    <img src={require('./../component/img/songkran/3.png')} className="img-fluid" />
                                </div>
                                <div className="col-md-10 col-8">
                                    <p className="py-2">
                                        {t('songkran_desc3')}
                                    </p>
                                </div>

                                <div className="col-md-11 col-8">
                                    <p className="py-2">
                                        {t('songkran_desc4')}
                                    </p>
                                </div>
                                <div class="col-md-12 col-12 align-self-center d-flex justify-content-center">
                                    <img src={language == "en" ? btnMiniConcert : btnMiniConcertTH} class="img-fluid btn-mini" />
                                </div>
                                <div className="col-md-2 col-4 align-self-center d-flex justify-content-center">
                                    <img src={require('./../component/img/songkran/4.png')} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <img src={require('./../component/img/songkran/palm-leaves-2.png')} className="img-fluid palm-leaves2 d-md-block d-none" />
                    <img src={require('./../component/img/songkran/palm-leaves-3.png')} className="img-fluid palm-leaves3" />
                </div> */}

                {/* <a href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + (getMobileOS().toLocaleLowerCase() === 'other' ? "/1" : "/5") : '##'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                    <img src={language == "en" ? floatingEN : floatingTH} class="img-fluid floating-cta d-md-block d-none" />
                </a>
                <a href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + (getMobileOS().toLocaleLowerCase() === 'other' ? "/1" : "/5") : '##'} onClick={() => onClickBanner(hardLink1.ButtonLinkUrl)}>
                    <img src={language == "en" ? floatingMobileEN : floatingMobileTH} class="img-fluid floating-cta-mobile d-block d-md-none" />
                </a> */}
            </a>

            {isLoading && (
                <div className="spinner">
                    <CircularProgress />
                </div>
            )}

            {/* Delete Confirm Popup ------------------------------------------------------- */}
            {/* <Modal show={showAlert} className="col-12 cart-placeorder">
                <a href="#" className="close"  ><Close className="popup-close" onClick={() => setShowAlert(false)} /></a>
                <h5 className="text-center text-danger mb-5">{t(alertText)}</h5>
                {notEqualArray && notEqualArray.map((item, index) => ((
                    <h5 className="text-center" key={index}>{item.PackageName} : {t('price')} {item.TotalPrice.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {t('thb')}</h5>
                )))}
                <div className="w-100 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <a className="w-100" href={isNavigate ? baseLink + routePath + '/ticket/' + hardLink1.ButtonLinkUrl + (getMobileOS().toLocaleLowerCase() === 'other' ? "/1" : "/5"): '##'}>
                        <button className="btn" style={{ height: '40px', background: '#d63737', borderRadius: '5px', fontWeight: '700', border: '0px', color: '#fff' }} onClick={() => deletePackage(hardLink1.ButtonLinkUrl)}>{t('delete')}</button>
                    </a>
                </div>
            </Modal> */}
        </div>
    );
};

export default LandingChina
