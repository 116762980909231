import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageLoader } from "react-image-and-background-image-fade";
import { useGlobalState } from '../global'
import ReactCardFlip from "react-card-flip";
import { ReactComponent as Verified } from '../component/img/check-green.svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

export const PackageCard4BuyFlip = (props) => {
    const { packageCard } = props
    const { packageList } = props
    const { netPrice } = props
    const { PackageItemlength } = props
    const { index } = props
    const { saveWord } = props
    const { routePath } = props
    const { selectedI } = props
    const { subTotalI } = props
    const { editI } = props
    const { itemQty } = props
    const { selectedDate } = props
    const { selectedTime } = props
    const { requiredDate } = props
    const { apiLink, baseLink } = props

    const [isFlipped, setIsFlipped] = useState(false)

    const setItemQty = (item) => {
        console.log("itemm qty", item)
        console.log("itemQty qty", itemQty)

        props.setItemQty(item);
    };

    const addToCart = (item, isEdit) => {
        props.addToCart(item, isEdit);
    };

    const setSelectedI = (item) => {
        props.setSelectedI(item);
    };

    const setSelectedDate = (item) => {
        props.setSelectedDate(item);
    };

    const setRequiredDate = (item) => {
        props.setRequiredDate(item);
    };

    const setRequiredTime = (item) => {
        props.setRequiredTime(item);
    };

    const handleClick = (e) => {
        e.preventDefault()
        setIsFlipped(!isFlipped)
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <>
            <div className="card-hottest-right" style={{ width: index < 2 ? '50%' : '30%', position: 'relative' }}>
                <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" style={{ height: '100%' }}>
                    {/* ด้านหน้า */}
                    <div>
                        <div className="card-body d-flex flex-column" style={{ padding: index < 2 ? '0px 10px 0px 10px' : '' }}>
                            <h5 className="mb-1 lh-1 border-bottom-dot" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: index < 2 ? '20px' : '24px', color: '#000000', paddingBottom: index < 2 ? '0px' : '', height: index < 2 ? '45px' : '60px' }}>{packageCard.PackageName}</h5>
                        </div>

                        <div className="card-footer text-muted" >
                            <div style={{ height: index < 2 ? 40 : 50, display: 'flex', justifyContent: 'center', alignItems: 'baseline', margin: packageCard.IsDiscount ? '' : '10px' }}>

                                <h1 className="weight-900 lh-1" style={{ fontSize: '40px', color: '#001D41', marginRight: '10px', fontWeight: '900' }}>{(packageCard.PackagePrice - packageCard.DiscountPrice).toLocaleString()} </h1>
                                <h1 style={{ fontSize: '20px', color: '#001D41' }}>{t('thb')} </h1>
                            </div>
                            {packageCard.IsDiscount && (
                                <>
                                    <div style={{ height: index < 2 ? 20 : 30, display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                                        <h1 className="weight-900 lh-1" style={{ fontSize: index < 2 ? '20px' : '25px', color: '#F87B17', marginRight: '5px', fontWeight: '900', textDecorationLine: 'line-through' }}>
                                            {(packageCard.PackagePrice).toLocaleString()} {t('thb')}
                                        </h1>
                                    </div>

                                    <div className="mb-1" style={{ minHeight: 40, justifyContent: 'center', display: 'flex' }}>

                                        <div className="save" style={{ height: index < 2 ? 50 : '', fontSize: index < 2 ? 18 : 20, textAlign: 'center' }}>{t(saveWord) + packageCard.DiscountPrice.toLocaleString() + ' ' + t('thb')}</div>

                                    </div>
                                </>
                            )}
                            <div className="area-detail" style={{ height: index < 2 ? '' : '11em', marginTop: index < 2 ? '0px' : '10px' }}>
                                {packageList.map((e, i) => {
                                    return (
                                        <div key={i} className="text-detail" style={{
                                            fontSize: index < 2 ? '13px' : '15px',
                                            width: index < 2 ? PackageItemlength > 4 ? '50%' : '100%' : PackageItemlength > 7 ? '50%' : '100%',
                                            paddingLeft: index < 2 ? PackageItemlength > 4 ? 20 : 50 : PackageItemlength > 7 ? 50 : 60,
                                        }}>
                                            {e.ItemType == 'Ticket' ? '●' : '+'} {e.Qty} {e.ItemName}
                                        </div>
                                    )

                                })}

                                <div className="text-center" style={{ position: 'absolute', bottom: '15px', left: '20px', width: '90%' }}>
                                    <a href={baseLink + routePath + "/ticket/" + packageCard.PackageCode + "/" + packageCard.StepId}>
                                        <button type="button" className="btn btn-main mt-auto mx-auto align-self-start "
                                            onClick={handleClick}
                                        >
                                            {t('buy_now')}
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ด้านหลัง */}
                    <div style={{height:'100%'}}>
                        <div className="card-header d-flex">
                            <a href="#" className="color-black lh-1 m-0">
                                <span className="material-icons flipButton font-14" onClick={handleClick}>arrow_back_ios</span>
                            </a>
                        </div>

                        <div className="card-body d-flex flex-column" style={{ padding: '0.5em 1em', height:'75%' }}>
                            {/* <h5 className="card-title mb-1 lh-1 p-2 text-center">{packageCard.PackageName}</h5> */}
                            <h5 className="card-title mb-1 lh-1 p-2 text-center" style={{height:'38px'}}>
                                {packageList.map((e, i) => {
                                    if (e.IsVisitDate == true) {
                                        return (i == 0 ? '' + e.ItemName : ',' + e.ItemName)
                                    }
                                })}
                            </h5>
                            {packageCard.IsDiscount && (
                                <div className="pt-3 text-center border-top">
                                    <p className="font-24 weight-900 color-orange mb-0 lh-1">{netPrice.toLocaleString()} {t('thb')}
                                        <span className="font-14 weight-600 color-black">/{t('round')}</span>
                                    </p>
                                    <p className="oldPrice weight-400">{packageCard.PackagePrice.toLocaleString()} {t('thb')}</p>
                                </div>
                            )}
                            {!packageCard.IsDiscount && (
                                <div className="mb-1">
                                    <p className="weight-400">{netPrice.toLocaleString()} {t('thb')}</p>
                                </div>
                            )}

                            <div className="row mb-3 pb-3 border-bottom">
                                <div className="col-4 m-auto">
                                    <p className="color-grey font-10 mb-0">{t('quantity')}</p>
                                </div>

                                <div className="col-8">
                                    <div className="input-group ms-auto">
                                        <span className="input-group-btn">
                                            <button type="button" name="quant[1]" className="btn btn-number minus" disabled={selectedI} data-type="minus" data-field="quant[1]" onClick={e => {
                                                if (itemQty > 1)
                                                    setItemQty(itemQty => itemQty - 1)
                                                else setItemQty(1)
                                            }}>

                                                <span className="material-icons">remove</span>
                                            </button>
                                        </span>

                                        <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={itemQty} defaultValue={1} disabled />
                                        <span className="input-group-btn">
                                            <button type="button" className="btn btn-number" data-type="plus" disabled={selectedI} data-field="quant[1]" onClick={e => { setItemQty(itemQty => itemQty + 1) }}>
                                                <span className="material-icons">add</span>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2 pb-3 border-bottom">
                                <div className="col-4 m-auto">
                                    <p className="color-grey font-10 mb-0">{t('visitdate')}
                                        {/* {packageCard.PackageGroupTypeCode === transportPackageGroupTypeCode && (<span> *</span>)} */}
                                        {<span> *</span>}
                                    </p>
                                </div>

                                <div className="col-8">
                                    <input id={packageCard.PackageCode + index} className={`form-control ${requiredDate}`} type="date" min={new Date().toISOString().split('T')[0]} value={selectedDate} disabled={selectedI} onChange={e => {
                                        const pickDate = e.target.value
                                        if (pickDate < new Date().toISOString().split('T')[0]) {
                                            let datePicker = document.getElementById(packageCard.PackageCode + index)
                                            setSelectedDate("")
                                            datePicker.focus()
                                        } else {
                                            setSelectedDate(e.target.value)
                                        }
                                    }} />

                                </div>
                            </div>
                            {/* 
                            {packageCard.PackageGroupTypeCode === transportPackageGroupTypeCode && (
                                <>
                                    <div className="row mb-2">
                                        <div className="col-4">
                                            <p className="color-grey font-10 mb-0">Location *</p>
                                        </div>

                                        <div className="col-8">
                                            <select className={`form-control ${requiredLocation}`} id="item_location" name="item_location" value={selectedLocation} disabled={selectedI} onChange={e => setSelectedLocation(e.target.value)}>
                                                <option value="">-</option>
                                                {
                                                    visitLocation.map((e, index) => {
                                                        return <option value={e.LocationName}>{e.LocationName}</option>
                                                    })
                                                }
                                            </select>

                                        </div>
                                    </div>

                                    <div className="row mb-0">
                                        <div className="col-4">
                                            <p className="color-grey font-10 mb-0">Time *</p>
                                        </div>

                                        <div className="col-8">
                                            <select className={`form-control ${requiredTime}`} id="transportation_schedule" name="item_time" disabled={selectedI} value={selectedTime} onChange={e => setSelectedTime(e.target.value)}>
                                                <option value="">-</option>
                                                <option disabled>1St Round</option>
                                                <option value="09:00-09:15">09:00-09:15</option>
                                                <option value="09:10-09:25">09:10-09:25</option>
                                                <option value="09:15-09:25">09:15-09:25</option>
                                                <option value="09:20-09:35">09:20-09:35</option>
                                                <option value="09:35-09:45">09:35-09:45</option>

                                                <option disabled>2nd Round</option>
                                                <option value="11:00-11:15">11:00-11:15</option>
                                                <option value="11:10-11:25">11:10-11:25</option>
                                                <option value="11:15-11:25">11:15-11:25</option>
                                                <option value="11:20-11:35">11:20-11:35</option>
                                                <option value="11:35-11:45">11:35-11:45</option>
                                            </select>
                                        </div>
                                    </div>
                                </>
                            )} */}
                        </div>


                        <div className="card-footer text-muted" style={{ height: '0px' }}>
                            <div className="text-center">
                                <a href={baseLink + routePath + "/ticket/" + packageCard.PackageCode + "/" + packageCard.StepId}>
                                    <button type="submit" disabled={selectedI} className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start"
                                        onClick={() => {
                                            setRequiredDate("")
                                            // setRequiredTime("")
                                            if (!packageCard.IsSaleByItem) {
                                                addToCart(
                                                    {
                                                        PackageCode: packageCard.PackageCode,
                                                        PackageName: packageCard.PackageName,
                                                        ItemCode: "",
                                                        ItemName: "",
                                                        ItemType: packageCard.ItemType,
                                                        Qty: itemQty,
                                                        Tax: 0,
                                                        ItemPrice: netPrice,
                                                        ItemDiscount: packageCard.IsDiscount ? packageCard.DiscountPrice : 0,
                                                        PriceName: packageCard.PriceName,
                                                        PriceUnit: '',
                                                        TotalPrice: netPrice,
                                                        VisitDate: selectedDate === "" ? null : selectedDate,
                                                        VisitTime: '',
                                                        VisitLocation: '',
                                                        step: packageCard.StepId,
                                                        status: 'normal',
                                                        IsPromoDiscount: !packageCard.IsDiscount,
                                                    }, false
                                                )
                                            }
                                            // setIsFlipped(!isFlipped)
                                        }}>{t('select')}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </ReactCardFlip>
            </div>
        </>
    )
}
