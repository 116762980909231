import React, { useEffect, useState } from 'react'
import { Markup } from "interweave";
import { useTranslation } from "react-i18next";
import noImg from '../img/no_image.png';
import { ReactComponent as Verified } from "../../component/img/check-green.svg";

export const SubItem = (props) => {

    const { itemSubList } = props
    const { currentStep } = props
    const { cartUpdate } = props

    let myCart = sessionStorage.getItem('MyCart')
    myCart = JSON.parse(myCart)

    const [picturePath, setPicturePath] = useState(noImg)

    const [netPrice, setNetPrice] = useState(0)
    const [itemQty, setItemQty] = useState(1)
    const [selectedI, setSelectedI] = useState(false)
    const [cartActive, setCartActive] = useState('')
    const [editI, setEditI] = useState(false)

    useEffect(() => {
        console.log("SubItem: ", itemSubList);
        if (itemSubList.PackagePictureLists.length > 0) {
            let picture = itemSubList.PackagePictureLists.filter(e => e.PictureSize.toLowerCase() === "small")[0]
            if (picture != null)
                setPicturePath(picture.PictureUrl)
            else
                setPicturePath(noImg)
        } 

        if (itemSubList.IsDiscount) {
            setNetPrice(itemSubList.PackagePrice - itemSubList.DiscountPrice)
        } else {
            setNetPrice(itemSubList.PackagePrice)
        }

        const pIndex = myCart.findIndex(e => {
            return (e.PackageCode === itemSubList.PackageCode)
        })

        if (pIndex !== -1) {
            // ถ้ามี Package อยู่ใน Cart
            setItemQty(myCart[pIndex].Qty)
            // เช็คว่า Package อยู่ใน Mode Edit หรือไม่
            if (myCart[pIndex].status === "edit") {
                setSelectedI(false)
                setCartActive('')
                setEditI(true)
            } else {
                setSelectedI(true)
                setCartActive('active')
            }
        } else {
            // ถ้าไม่มี Package อยู่ใน Cart
            setSelectedI(false)
            setCartActive('')
            setEditI(false)
        }
    }, [itemSubList])

    useEffect(() => {
        if (cartUpdate.PackageCode === itemSubList.PackageCode) {
            setItemQty(cartUpdate.Qty)
        }
    }, [cartUpdate])

    const addToCart = (item, isEdit) => {
        props.onAddToCart(item, isEdit)
    }

    // ภาษา
    const { t } = useTranslation();

    return (
        <div className="col-lg-4 mb-4 d-flex align-items-stretch" style={{height:'460px'}}>
            <div className={`card-flip h-100 ${cartActive}`}>
                <img src={picturePath} className="card-img-top" alt="Card Image" style={{height: 'unset'}} />

                <div className="card-body d-flex flex-column">
                    <h5 className="card-title mb-1 lh-1">{itemSubList.PackageName}</h5>
                    <p className="card-text mb-4 font-12 color-grey weight-500"><Markup content={itemSubList.PackageDescription} /></p>
                </div>

                <div className="card-footer text-muted">
                    {itemSubList.IsDiscount && (
                        <div className="mb-4">
                            <p className="font-24 weight-900 color-orange mb-0 lh-1">{netPrice.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')}<span className="font-14 weight-600 color-black">{itemSubList.PriceUnit}</span></p>
                            <p className="oldPrice weight-400">{itemSubList.PackagePrice.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')}</p>
                        </div>
                    )}
                    {!itemSubList.IsDiscount && (
                        <div className="mb-4">
                            <p className="weight-400">{netPrice.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})} {t('thb')}</p>
                        </div>
                    )}
                    <div className="input-group mx-auto my-3">
                        {!selectedI && (
                            <span className="input-group-btn">
                                <button type="button" className="btn  btn-number minus" data-type="minus" disabled={selectedI} data-field="quant[1]" onClick={e => {
                                    if (itemQty > 1)
                                        setItemQty(itemQty => itemQty - 1)
                                    else setItemQty(1)
                                }}>
                                    <span className="material-icons">remove</span>
                                </button>
                            </span>
                        )}

                        <input type="text" name="quant[1]" className="form-control input-number" style={{ marginLeft: "-3px" }} value={itemQty} defaultValue={1} disabled />

                        {!selectedI && (
                            <span className="input-group-btn">
                                <button type="button" className="btn btn-number" data-type="plus" disabled={selectedI} data-field="quant[1]" onClick={e => { setItemQty(itemQty => itemQty + 1) }}>
                                    <span className="material-icons">add</span>
                                </button>
                            </span>
                        )}

                    </div>
                    {selectedI && (
                        <div className="text-center">
                            <Verified className="img-" />
                        </div>
                    )}
                    {!selectedI && (
                        <div className="text-center">
                            <button type="button" className="btn btn-blue btn-not-full text-white mt-auto mx-auto align-self-start "
                                onClick={() => {
                                    addToCart(
                                        {
                                            PackageCode: itemSubList.PackageCode,
                                            PackageName: itemSubList.PackageName,
                                            ItemCode: "",
                                            ItemName: "",
                                            Qty: itemQty,
                                            Tax: 0,
                                            ItemPrice: netPrice,
                                            ItemDiscount: itemSubList.IsDiscount ? itemSubList.DiscountPrice : 0,
                                            PriceName: itemSubList.PriceName,
                                            PriceUnit: '',
                                            TotalPrice: itemQty * netPrice,
                                            VisitDate: null,
                                            VisitTime: '',
                                            VisitLocation: '',
                                            step: currentStep,
                                            status: 'normal',
                                            IsPromoDiscount: !itemSubList.IsDiscount,
                                        }, editI
                                    )
                                    if (itemQty > 0) {
                                        setSelectedI(true)
                                        setCartActive('active')
                                    }
                                }}
                            >{t('add')}</button>
                        </div>
                    )}

                </div>
            </div>

        </div>
    )
}
